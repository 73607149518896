import {useState, useEffect} from 'react'
import {Button, Input, Select, DatePicker} from '~/components/ui'
import {useRepository, useError, useToast} from '~/components/hooks'
import {useBreadcrumbs} from '~/context/breadcrumb'
import {groupBy} from '~/utils/Array'
import {Account} from '~/api/models'

const baseState = {
  account_id: '',
  provider: '',
  number: '',
  amount: '',
  issued_at: ''
}


const CreatePayment = () => {
  const { setBreadcrumb } = useBreadcrumbs()
  const { setErrors, error } = useError()
  const toast = useToast()

  const [state, setState] = useState(baseState)
  const [loadings, setLoadings] = useState({ accounts: false, store: false })
  const [accounts, setAccounts] = useState<Account[]>([])

  const { fetchAccounts, storeIncome } = useRepository(({ accounts, incomes, reports }) => ({
    fetchAccounts: accounts.index,
    storeIncome: incomes.create,
    //paymentReportURL: reports.payment,
  }))

  useEffect(() => {
    setLoadings(prev => ({...prev, accounts: !prev.accounts}))
    fetchAccounts<Account[]>({ limit: 0, with: 'bank', scope: 'Empresa' })
      .then(setAccounts)
      .finally(() => setLoadings(prev => ({...prev, accounts: !prev.accounts})))

    setBreadcrumb([
      { title: 'Ingresos', to: '/incomes' },
      { title: 'Registrar ingreso', to: '/incomes/create' },
    ])

    return () => setBreadcrumb([])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleStore = () => {
    setLoadings(prev => ({...prev, store: !prev.store}))
    storeIncome(state)
      .then(() => {
        toast.success({
            title: 'Se ha registrado el ingreso!',
            message: "",         
            lifetime: -1,
        })
        setState(baseState)
      })
      .catch(setErrors())
      .finally(() => setLoadings(prev => ({...prev, store: !prev.store})))
  }

  return (
    <>
      <div className="w-full p-2">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full xl:w-5/6 px-2 flex flex-wrap">
            <h1 className="text-2xl mb-4">
              Registrar ingreso
            </h1>
            <div className="w-full flex flex-wrap p-2 rounded-2xl">
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Select
                  required
                  hint="De la empresa"
                  id="account_id"
                  label="A la cuenta"
                  error={error('account_id')}
                  value={state.account_id}
                  loading={loadings.accounts}
                  onChange={account_id => {
                    console.log("account_id",account_id);
                    
                    return setState(prev => ({...prev, account_id: account_id as string}))
                  }}>
                  <option value="">-- Cuenta --</option>
                  {Object.entries(groupBy(accounts, 'type')).map(([attribute, value]) => (
                    <optgroup key={attribute} label={attribute}>
                      {value.map(($0) => (
                        <option title={$0.number.toString()} value={$0.id} key={`${attribute}_${$0.id}`}>
                          {$0.bank?.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Input
                  required
                  id="provider"
                  hint="Origen del ingreso (Proveedor, prestamo...)"
                  label="De"
                  error={error('provider')}
                  value={state.provider}
                  onChange={provider => setState(prev => ({...prev, provider}))} />
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Input
                  required
                  hint="&nbsp;"
                  id="amount"
                  label="Monto"
                  error={error('amount')}
                  value={state.amount}
                  onChange={amount => setState(prev => ({...prev, amount}))} />
              </div>

              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  required
                  id="number"
                  error={error('number')}
                  label={`Número de transacción`}
                  value={state.number}
                  onChange={number => setState(prev => ({...prev, number}))} />
              </div>

              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <DatePicker
                  required
                  id="issued_at"
                  label="Fecha"
                  value={state.issued_at}
                  // error={error('issued_at')}
                  onChange={([issued_at]) => setState(prev => ({...prev, issued_at}))} />
              </div>
            </div>
          </div>

          <div className="flex w-full xl:w-1/6 flex-col space-y-2 px-2 mt-4 xl:mt-0">
            <Button onClick={handleStore}>{loadings.store ? 'Procesando...' : 'Guardar'}</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePayment
